<template>
  <div>
      <b-card
        no-body
        class="card-statistics"
      >
        <b-card-header>
          <b-card-title>Statistics</b-card-title>
        </b-card-header>
        <b-card-body class="">
          <b-row>
            <b-col
              xl="4"
              sm="34"
            >
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="24"
                      icon="ClipboardIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body style="cursor: pointer;" class="my-auto" @click="get_data('terms')">
                  <h4 class="font-weight-bolder mb-0" v-if="stats.terms !== null">
                    {{stats.terms.count}}
                  </h4>
                  <h4 class="font-weight-bolder mb-0" v-if="stats.terms === null">
                    0
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Terms
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>

            <b-col
              xl="4"
              sm="4"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-info"
                  >
                    <feather-icon
                      size="24"
                      icon="RefreshCwIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body style="cursor: pointer;" class="my-auto" @click="get_data('dues')">
                  <h4 class="font-weight-bolder mb-0">
                    {{stats.dues.count}}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Dues
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>

            <b-col
              xl="4"
              sm="4"
            >
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-warning"
                  >
                    <feather-icon
                      size="24"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    ${{stats.dues.total}}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Dues Monthly Income
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>



          </b-row>
        </b-card-body>
      </b-card>
    <b-row>
      <b-col
        cols="12"
        md="2"
        class="align-items-center justify-content-start mb-1 mb-md-0"
      >
      <v-select
         v-model="type"
         :reduce="type => type.value"
        :options="statustype"
        :clearable="false"
        :filterable="false"
        style="display:block"
      />
      </b-col>
      <b-col
        cols="12"
        md="5"
        class="align-items-center justify-content-start mb-1 mb-md-0"
      >
    </b-col>
      <b-col
        cols="12"
        md="5"
      >
        <div class="d-flex align-items-center justify-content-end">
          <flat-pickr
          v-model="rangeDate"
          class="form-control mb-1 mr-1"
          :config="{ mode: 'range'}"
        />
          <b-button
            variant="primary"
            class="mb-1"
            @click="exportToExcel()"
          >
            <span class="text-nowrap">Export</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <!-- table -->
    <b-table
      ref="selectableTable"
      :items="items"
      :fields="fields"
      responsive
      striped
      class="mb-0"
    >
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :to="{ name: 'apps-Member-view', params: { id: data.item.id } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Details</span>
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'apps-Member-edit', params: { id: data.item.id } }">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(renewed)="data">
        <b-badge variant="light-success" v-if="data.item.renewed == 'Y'">
           Success
        </b-badge>
        <b-badge variant="light-primary" v-if="data.item.type == 'DUES'">
           Dues
        </b-badge>
        <b-badge variant="danger" v-if="data.item.renewed == 'N' && data.item.type != 'DUES'">
           Pending
        </b-badge>
      </template>
      <template #cell(status_number)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(member_code)="data">
         <b-link :to="'/apps/member/edit/'+data.item.token+'/'"><b>{{data.item.member_code}}</b></b-link>
      </template>
    </b-table>
    <b-row>
      <b-col class="mt-1">
        Total : {{ totalRows }} , Display Row {{ startRow }} to {{ endRow }}
      </b-col>
      <b-col>
        <!--<b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          align="right"
          size="md"
          class="my-0"
          @change="handleChangePage"
        /> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import { 
  BTable, BAvatar,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCard,
  BMedia,
  BMediaAside,
  BMediaBody, BCardBody, BBadge, BLink, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BInputGroupPrepend,BModal, VBModal} from 'bootstrap-vue'
export default {
  components: {
    moment,
    BLink,
    flatPickr,
    BTable,
    BModal, VBModal,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BPagination,
    BCardCode,
    BCard,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      iframe_src : '//cloudfit.pro/invoice.php',
      rangeDate: null,
      searchString: null,
      type : '',
      statustype: [
        { label: 'ACTIVE', value: 'ACTIVE' },
        { label: 'DISABLED', value: 'DISABLED' },
        { label: 'FREEZE', value: 'FREEZE' },
        { label: 'TERMINATE', value: 'TERMINATE' },
      ],
      test_string : null,
      headerTitle: ['Tranasction ID', 'Date Time','Amount', 'Member Code', 'Method'],
      headerVal: ['code', 'created_at','amount', 'member_code', 'method'],
      status: [{
        1: 'ACTIVE', 2: 'DISABLED', 3: 'FREEZE', 4: 'TERMINATE', 5: 'FREEZE',
      },
      {
        1: 'light-success', 2: 'danger', 3: 'info', 4: 'primary', 5: 'light-info',
      }],
      statusOptions: [
        { label: 'Active', value: 'active' },
        { label: 'Disabled', value: 'disabled' },
      ],
      centerOptions: [],
      stats: {},
      isAddNewMemberSidebarActive: false,
      searchString: null,
      perPage: 10,
      startRow: 1,
      endRow: 10,
      totalAmount: 0,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      fields: [{ key: 'code', label: 'Plan' },{ key: 'renewed', label: 'renew' },{ key: 'type', label: 'Type' }, { key: 'start', label: 'Start' }, { key: 'end', label: 'Expire' },{ key: 'amount', label: 'Amount' }, { key: 'member_code', label: 'Member Code' },{ key: 'mobile', label: 'Mobile' },],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Active', 2: 'Disabled', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-success', 2: 'light-danger', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'single',
      selected: [],
    }
  },
  watch: {
    type: {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },

    rangeDate : {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    currentPage: {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    searchString: {
      handler(newValue, oldValue) {
        // console.log("search")
        this.searchString = newValue.toUpperCase()
        this.handleSearch()
      },
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue < 9) {
          this.perPage = 10
        }
        // console.log("search")
        this.handleSearch()
      },
    },
  },
  created() {
    this.get_data()
    this.get_center_data()
  },
  methods: {
    exportToExcel() {
      console.log(this.items)
      console.log(this.headerTitle)
      import('@/@core/Export2Excel').then(excel => {
        const list = this.items
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: 'daily_sales',
          autoWidth: true,
          bookType: 'xlsx'
        })
        //this.clearFields()
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          // Add col name which needs to be translated
          // if (j === 'timestamp') {
          //   return parseTime(v[j])
          // } else {
          //   return v[j]
          // }

          return v[j]
        })
      )
    },
    get_data(searchString = null) {
      var splitted = []
      if (this.rangeDate === null) {
        splitted[0] = moment().subtract(15, 'days').format('YYYY-MM-DD')
        splitted[1] = moment().add(15, 'days').format("YYYY-MM-DD")
        this.rangeDate = moment().subtract(15, 'days').format('YYYY-MM-DD') + " to " + moment().add(15, 'days').format("YYYY-MM-DD")
      } else {
        splitted = this.rangeDate.split(" to ");
      }
      this.$http.get(process.env.VUE_APP_API_BASE+`/report/membership/`+this.$store.getters['center/currentCenterId'] + `/?type=`+searchString+`&status=`+this.type+`&from=`+splitted[0]+`&to=`+splitted[1])
        .then(res => {
          if (res.data.result === true) {
            this.items = res.data.records
            this.stats = res.data.stats
            this.totalRows = this.items.length
            this.totalAmount = 0.00
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
          }
        })
    },
    get_center_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/centers/')
        .then(res => {
          this.centerOptions = res.data.centers
        })
    },
    handleChangePage() {

    //  this.get_data()
    },
    onRowSelected(items) {
      this.selected = items[0]
      console.log(items)
      if (items[0].is_pdf == 'Y') {
        this.iframe_src = '//www.cloudfit.pro/pdf/INVOICE_'+items[0].code+'.pdf'
        console.log(this.iframe_src)

      } else {
      this.iframe_src = process.env.VUE_APP_API_BASE+'/invoice.php?print=n&code='+items[0].id
      }
      this.transaction_id = items[0].transaction_id
      this.$refs['my-modal'].show()
    },
    close() {
      this.iframe_src = '//www.cloudfit.pro/invoice.php'
      this.$refs['my-modal'].hide()
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    handleSearch() {
      // let _print = `The user searched for: ${searching}\n`
      // this.$refs.pre.appendChild(document.createTextNode(_print))
    // this.searchString = searching
    // this.currentx = 1
    // this.get_data()
      this.searchTimeOut()
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        // your code
        this.currentPage = 1
        this.get_data(this.searchString)
      }, 500)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
